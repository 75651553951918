/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

// https://www.michalspacek.com/using-javascript-to-modify-urls-and-hide-fbclid
;(function () {
  const param = "fbclid"
  if (window.location.search.indexOf(param + "=") !== -1) {
    var replace = ""
    try {
      var url = new URL(window.location)
      url.searchParams.delete(param)
      replace = url.href
    } catch (ex) {
      var regExp = new RegExp("[?&]" + param + "=.*$")
      replace = window.location.search.replace(regExp, "")
      replace = window.location.pathname + replace + window.location.hash
    }
    window.history.replaceState(null, "", replace)
  }
})()
